<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid">
      <div class="row">
        <HomeSection
          id="sec1"
          v-if="!mobile"
          class="homesec"
          :imgLeft="false"
          :infoSec="locale[lang].MEMBERS.INFOSEC1"
          :haveButton="true"
          :link="
            locale[lang].MEMBERS.LINKS[0]
          "
        />
        <MobileSec
          id="mobile1"
          class="mobileSec"
          v-else
          :infoSec="locale[lang].MEMBERS.INFOSEC1_MOBILE"
          :titleTop="false"
          :haveButton="true"
          :link="
            locale[lang].MEMBERS.LINKS[0]
          "
        />
      </div>
      <div class="row">
        <HomeSection
          id="sec2"
          v-if="!mobile"
          class="homesec"
          :imgLeft="true"
          :infoSec="locale[lang].MEMBERS.INFOSEC2"
          :haveButton="true"
          :link="locale[lang].MEMBERS.LINKS[1]"
        />
        <MobileSec
          id="mobile2"
          class="mobileSec"
          v-else
          :infoSec="locale[lang].MEMBERS.INFOSEC2_MOBILE"
          :titleTop="false"
          :haveButton="true"
          :link="locale[lang].MEMBERS.LINKS[1]"
        />
      </div>
      <div class="row">
        <div class="col-12" id="last">
          <div class="desktop" v-if="!mobile">
            <h2>{{locale[lang].MEMBERS.PROBLEME.TITLE}}</h2>
            <p>
              {{locale[lang].MEMBERS.PROBLEME.CONTENT.TEXT_1}}
              <a href="#" target="_blank" rel="noopener noreferrer">{{locale[lang].MEMBERS.PROBLEME.CONTENT.TEXT_2}}</a>,
             {{locale[lang].MEMBERS.PROBLEME.CONTENT.TEXT_3}}
            </p>
          </div>
          <div class="mobile" v-else>
            <p>
              {{locale[lang].MEMBERS.PROBLEME.CONTENT_MOBILE}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container">
      <Footer />
    </div>
  </div>
</template>

<script>
import HomeSection from "@/components/Home/HomeSection.vue";
import MobileSec from "@/components/MobileSec.vue";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";

export default {
  components: {
    HomeSection,
    MobileSec,
    NavBar,
    Footer,
  },
  data() {
    return {
      mobile: false,
    };
  },
  created() {
    var w = window.innerWidth;
    if (w <= 1024) {
      this.mobile = true;
    }
  },
};
</script>

<style scoped>
#sec1,
#mobile1 {
  background-image: url("../assets/Images/Background/Grupo 1013.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 65% 110%;
  padding: 0 100px 8% 100px;
}

#sec2,
#mobile2 {
  background-image: url("../assets/Images/Background/Grupo 691-Members.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 81% 100%;
  padding: 0 100px 4% 100px;
}

#last {
  background-image: url("../assets/Images/Background/Grupo 129@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 125%;
  height: 500px;
  padding-top: 250px;
}

h2 {
  text-align: center;
  font: normal normal bold 34px/47px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
}

p {
  text-align: center;
  font: normal normal 500 28px/47px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
}

a {
  text-decoration: underline;
  font: normal normal 500 28px/47px Montserrat;
  letter-spacing: 0px;
  color: #ff0313;
}

.homesec >>> .title-sec {
  margin-bottom: 100px;
  position: relative;
}

#sec1 >>> .title-sec::after, #sec2 >>> .title-sec::after  {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  bottom: -40px;
  left: 0;
  opacity: 1;
}

.homesec >>> h2 {
  text-align: left;
  font: normal normal bold 38px/50px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

.homesec >>> .button-sec {
  text-align: left;
}

.homesec >>> button {
  text-align: center;
  font: normal normal bold 22px/27px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 20px 90px;
  display: block;
}

.homesec >>> img {
  height: 600px;
}

#sec1 >>> h3 {
  text-align: left;
  font: normal normal 600 27px/32px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

#sec2 >>> h3 {
  text-align: left;
  font: normal normal 500 28px/32px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

#footer-container {
  background-color: #0F1010;
}

@media (max-width: 1900px) {
  .homesec >>> img {
    height: 425px !important;
  }

  .homesec >>> h2 {
    font-size: 28px !important;
    line-height: 38px !important;
  }

  .homesec >>> .title-sec {
    margin-bottom: 40px !important;
  }

  .homesec >>> h3 {
    font-size: 22px !important;
    line-height: 26px !important;
  }

  .homesec >>> button {
    font-size: 18px !important;
    line-height: 20px !important;
    padding: 15px 50px !important;
  }
}

@media (max-width: 1412px) {
  #sec1 >>> .title-sec::after,#sec2 >>> .title-sec::after  {
    bottom: -25px;
  }
}

@media (max-width: 1370px) {
  #sec1 {
    padding: 0 80px 8% 80px;
  }

  #sec2 {
    padding: 0 80px 4% 80px;
  }
}

@media (max-width: 1024px) {

  #footer-container {
    background-color: #E8E8E9;
  }

  .mobileSec >>> h3 {
    text-align: center;
    font: normal normal 500 16px/18px Montserrat;
    letter-spacing: 0.2px;
    color: #242424;
    opacity: 1;
  }

  .mobileSec >>> a {
    margin-top: 20px;
  }

  .mobileSec >>> .btn {
    height: auto;
    background-color: #f7f7f7;
    padding: 20px 30px;
  }

  #mobile1 {
    background-size: 50%;
    background-position: 55% 100%;
    padding-bottom: 30%;
  }

  #mobile1 >>> .title-sec {
    position: relative;
    margin-bottom: 47px;
  }

  #mobile1 >>> .title-sec::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 92px;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 1;
  }

  #mobile2 {
    margin-bottom: 40px;
  }

  #mobile2 >>> .title-sec {
    display: none;
  }

  #last {
    background-image: url("../assets/Images/Background/Enmascarar grupo 216.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding: 25% 10% 15% 10%;
  }

  #last .mobile p {
    text-align: center;
    font: normal normal 500 16px/24px Montserrat;
    letter-spacing: 0.26px;
    color: #090909;
  }
}

@media (max-width: 525px) {
  .mobileSec {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (max-width: 375px) {
  #last {
    background-size: auto;
  }
}
</style>
